import React, { memo, useMemo } from "react"

import { useImage } from "../../hooks/useImage"

export const withMaintenance = Component =>
  memo(({ name = "Maintenance", page, location }: any) => {
    const { getFluidImage } = useImage()

    const image = getFluidImage(page?.image, { maxWidth: 1000 })

    Component.displayName = name
    return useMemo(() => <Component image={image} location={location} title={page?.title} />, [image, location, page])
  })
