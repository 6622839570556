import React from "react"

import { withMaintenanceForm } from "./withMaintenanceForm"
import { FormInput } from "../../Form/Input/FormInput"
import { StyledButton } from "../../Styled/Button"
import { StyledErrors } from "../../Styled/Form"
import { Form } from "../styledMaintenance"

export const MaintenanceForm = withMaintenanceForm(
  ({ data, error, handleChange, handleSubmit, locales }): JSX.Element => (
    <>
      <Form onSubmit={handleSubmit}>
        <FormInput
          autoFocus
          full
          inverted
          name={`password`}
          onChange={handleChange}
          placeholder={locales?.additionalPassword}
          required
          type={`password`}
          value={data?.password}
          withSpacing={!error}
        />

        {error && (
          <StyledErrors inverted withSpacing>
            {locales?.additionalError}
          </StyledErrors>
        )}

        <StyledButton colour={`orange`} full size={`primary`} type={`submit`}>
          {locales?.additionalSubmit}
        </StyledButton>
      </Form>
    </>
  )
)
