import React from "react"

import { withMaintenance } from "./withMaintenance"
import { Icon } from "../Icon/Icon"
import { MaintenanceForm } from "./Form/MaintenanceForm"
import { StyledContainer } from "../Styled/Container"
import { StyledColumn } from "../Styled/Column"
import { StyledImage } from "../Styled/Image"
import { StyledRow } from "../Styled/Row"
import { Content, Image, Page, Title } from "./styledMaintenance"

export const Maintenance = withMaintenance(
  ({ image, location, title }): JSX.Element => (
    <Page>
      <StyledContainer width={`xl`}>
        <StyledRow gutter items={`center`} withWrap>
          <StyledColumn gutter width={`md:1/2`}>
            <Content>
              <Icon colour={`white`} name={`logo`} width={190} withSpacing={`lg`} />
              <Title colour={`white`} withSpacing={`lg`}>
                {title}
              </Title>
              <MaintenanceForm location={location} />
            </Content>
          </StyledColumn>
          <StyledColumn gutter width={`md:1/2`}>
            {image && (
              <Image>
                <StyledImage alt={image?.alt || title} fluid={image} />
              </Image>
            )}
          </StyledColumn>
        </StyledRow>
      </StyledContainer>
    </Page>
  )
)
