import React, { memo, useCallback, useMemo } from "react"

import { useMaintenance } from "../../../hooks/useMaintenance"

export const withMaintenanceForm = Component =>
  memo(({ name = "MaintenanceForm", location }: any) => {
    const { validatePassword, data, setData, setError, error, locales } = useMaintenance(location)

    const handleSubmit = useCallback(
      (event: any) => {
        event.preventDefault()
        validatePassword()
      },
      [validatePassword]
    )

    const handleChange = useCallback(
      ({ target: { type, name, value, checked } }) => {
        setData(prevState => ({
          ...prevState,
          [name]: type === "checkbox" ? checked : value,
        }))
        setError(false)
      },
      [setData, setError]
    )

    Component.displayName = name
    return useMemo(
      () => <Component data={data} error={error} handleChange={handleChange} handleSubmit={handleSubmit} locales={locales} />,
      [data, error, handleChange, handleSubmit, locales]
    )
  })
