import tw, { styled } from "twin.macro"

import { StyledForm } from "../Styled/Form"
import { H1 } from "../Styled/Text"

export const Page = tw.main`h-full overflow-x-hidden overflow-y-auto py-6 flex flex-col items-center justify-start md:justify-center`
export const Content = tw.div`relative z-1`
export const Form = tw(StyledForm)`md:w-102.5`
export const Image = tw.div`absolute right-0 top-0 mt-16 -mr-12 w-full md:inset-auto md:static`
export const Title = styled(H1)`
  margin-top: 100%;
  ${tw`md:mt-0`}
`
